/* You can add global styles to this file, and also import other style files */
/* html, body { height:100%; } */
body {
  margin: 0;
  font-family: 'Poppins Medium';
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
}

section {
  font-family: 'Poppins Medium';
  color: #63398b;
}

h4{
  font-family: 'Poppins Medium';
  color: #63398b;
}

div{
  font-family: 'Poppins Medium';
  color: #63398b;
}
@font-face {
  font-family: 'Poppins Medium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}